import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { URL_BOARD } from './Url';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PostDetail = ({ userInfo, isLoggedIn, boardName }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [editingComment, setEditingComment] = useState(null);
    const [editingContent, setEditingContent] = useState('');
    const [status, setStatus] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [quillContent, setQuillContent] = useState('');
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image1Deleted, setImage1Deleted] = useState(false);
    const [image2Deleted, setImage2Deleted] = useState(false);
    const titleRef = useRef(null);

    // CSRF 토큰 가져오기
    const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

    useEffect(() => {
        axios.get(`${URL_BOARD}/${boardName}/${id}`, {
            headers: {
                'X-CSRF-Token': csrfToken
            }
        })
            .then(response => {
                if (response.data.status !== 'success') {
                    setStatus(response.data.status);
                } else {
                    setPost(response.data.post);
                    setComments(response.data.post.comments || []);
                    setQuillContent(response.data.post.content);
                }
            })
            .catch(error => {
                console.error("Error fetching post content:", error);
                setStatus('Error fetching post content');
            });
    }, [boardName, id, csrfToken]);

    const handleUpdate = async () => {
        if (titleRef.current && quillContent) {
            const title = titleRef.current.value;
            const content = quillContent;

            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            if (image1Deleted) formData.append('deleteImage1', 'true');
            if (image2Deleted) formData.append('deleteImage2', 'true');
            if (image1) formData.append('image1', image1);
            if (image2) formData.append('image2', image2);

            await axios.put(`${URL_BOARD}/${boardName}/${post.id}`, formData, {
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Content-Type': 'multipart/form-data'
                }
            });

            setIsEditing(false);
            setImage1Deleted(false);
            setImage2Deleted(false);

            axios.get(`${URL_BOARD}/${boardName}/${id}`, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            })
                .then(response => {
                    setPost(response.data.post);
                    setQuillContent(response.data.post.content);
                })
                .catch(error => {
                    console.error("Error fetching post content:", error);
                });
        }
    };

    const handleCommentSubmit = async () => {
        if (newComment) {
            const commentData = {
                post_id: post.id,
                content: newComment,
            };

            try {
                await axios.post(`${URL_BOARD}/posts/${post.id}/comments`, commentData, {
                    headers: {
                        'X-CSRF-Token': csrfToken
                    }
                });
                setNewComment(''); // 댓글 작성 후 입력창 초기화
                const response = await axios.get(`${URL_BOARD}/${boardName}/${post.id}`, {
                    headers: {
                        'X-CSRF-Token': csrfToken
                    }
                });
                setComments(response.data.post.comments || []);
            } catch (error) {
                console.error("Error submitting comment:", error);
            }
        }
    };

    const startEditingComment = (comment) => {
        setEditingComment(comment);
        setEditingContent(comment.content);
    };

    const handleCommentUpdate = async () => {
        if (editingComment) {
            try {
                await axios.put(`${URL_BOARD}/comments/${editingComment.id}`, { content: editingContent }, {
                    headers: {
                        'X-CSRF-Token': csrfToken
                    }
                });
                setEditingComment(null);
                setEditingContent('');
                const response = await axios.get(`${URL_BOARD}/${boardName}/${post.id}`, {
                    headers: {
                        'X-CSRF-Token': csrfToken
                    }
                });
                setComments(response.data.post.comments || []);
            } catch (error) {
                console.error("Error updating comment:", error);
            }
        }
    };

    const handleCommentDelete = async (commentId) => {
        try {
            await axios.delete(`${URL_BOARD}/comments/${commentId}`, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });
            const response = await axios.get(`${URL_BOARD}/${boardName}/${post.id}`, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });
            setComments(response.data.post.comments || []);
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    };

    const handleCancelEdit = () => {
        setEditingComment(null);
        setEditingContent('');
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this post?")) {
            await axios.delete(`${URL_BOARD}/${boardName}/${post.id}`, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });
            navigate(`/${boardName}`); // 글 삭제 후 게시판으로 이동
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setImage1Deleted(false);
        setImage2Deleted(false);
    };

    const handleImage1Delete = () => {
        setImage1Deleted(true);
        setImage1(null); // 이미지를 삭제하면 새로운 이미지로 null을 설정
    };

    const handleImage2Delete = () => {
        setImage2Deleted(true);
        setImage2(null); // 이미지를 삭제하면 새로운 이미지로 null을 설정
    };

    const renderCommentTime = (comment) => {
        const createdAt = new Date(comment.created_at);
        const updatedAt = new Date(comment.updated_at);

        return (
            <>
                <small className="text-muted">Created: {createdAt.toLocaleString()}</small>
                {new Date(updatedAt).getTime() !== new Date(createdAt).getTime() && (
                    <small className="text-muted ms-2">Updated: {new Date(updatedAt).toLocaleString()}</small>
                )}
            </>
        );
    };

    return (
        <div className="container mt-3">
            {status ? (
                <div className="alert alert-danger" role="alert">
                    {status}
                    <br />
                    <br />
                    <button className="btn btn-secondary mt-2" onClick={() => navigate(`/${boardName}`)}>Back to List</button>
                </div>
            ) : (
                post && (
                    <div className="card mb-4 p-4">
                        <h6 className="text-muted">Board: {post.board_name}</h6>
                        {!isEditing ? (
                            <>
                                <h2>{post.title}</h2>
                                <p className="text-muted">
                                    By {post.userinfo.name} on {new Date(post.created_at).toLocaleDateString()}
                                </p>

                                {/* 이미지 출력 섹션 */}
                                {post.image1 && !image1Deleted && (
                                    <img src={`${post.image1}`} alt="Image 1" style={{ maxWidth: '100%', marginBottom: '10px' }} />
                                )}
                                {post.image2 && !image2Deleted && (
                                    <img src={`${post.image2}`} alt="Image 2" style={{ maxWidth: '100%', marginBottom: '10px' }} />
                                )}

                                <div className="post-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }} />
                                <div className="d-flex justify-content-between mt-2">
                                    <button className="btn btn-secondary" onClick={() => navigate(`/${boardName}`)}>Back to List</button>
                                    <div>
                                        {isLoggedIn && userInfo.id === post.user_id && (
                                            <>
                                                <button className="btn btn-primary me-2" onClick={() => setIsEditing(true)}>Edit</button>
                                                <button className="btn btn-danger" onClick={handleDelete}>Delete</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <hr />
                                <h5>Comments</h5>
                                {comments.length > 0 ? (
                                    comments.map((comment, index) => (
                                        <div key={index} className="mb-3 p-3 border rounded" style={{ backgroundColor: "#f9f9f9" }}>
                                            <strong>{comment.userinfo.name}</strong> ({new Date(comment.created_at).toLocaleDateString()})
                                            <br /><br />
                                            {editingComment && editingComment.id === comment.id ? (
                                                <>
                                                    <ReactQuill value={editingContent} onChange={setEditingContent} />
                                                    <button className="btn btn-primary mt-2 me-2" onClick={handleCommentUpdate}>Update</button>
                                                    <button className="btn btn-secondary mt-2" onClick={handleCancelEdit}>Cancel</button>
                                                </>
                                            ) : (
                                                <>
                                                    <p style={{ lineHeight: "0.5em" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.content) }} />
                                                    {renderCommentTime(comment)}
                                                    {(isLoggedIn && (userInfo.id === comment.user_id || userInfo.is_admin)) && (
                                                        <div className="mt-2">
                                                            <button className="btn btn-sm btn-primary me-2" onClick={() => startEditingComment(comment)}>Edit</button>
                                                            <button className="btn btn-sm btn-danger" onClick={() => handleCommentDelete(comment.id)}>Delete</button>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p>No comments yet.</p>
                                )}
                                {isLoggedIn && (
                                    <div className="mt-4">
                                        <h6>Add a Comment</h6>
                                        <ReactQuill value={newComment} onChange={setNewComment} />
                                        <button className="btn btn-primary mt-2" onClick={handleCommentSubmit}>Submit</button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <input type="text" ref={titleRef} className="form-control mb-2" defaultValue={post.title} />
                                <ReactQuill
                                    value={quillContent}
                                    onChange={setQuillContent}
                                    style={{ height: '300px', marginBottom: '40px' }}
                                />
                                <div className="mb-2">
                                    {post.image1 && !image1Deleted && (
                                        <div className="d-flex align-items-center">
                                            <img src={`${post.image1}`} alt="Image 1" style={{ maxWidth: '200px', marginRight: '10px' }} />
                                            <button className="btn btn-danger" onClick={handleImage1Delete}>Delete Image 1</button>
                                        </div>
                                    )}
                                    <input type="file" className="form-control mt-2" onChange={(e) => setImage1(e.target.files[0])} />
                                </div>
                                <div className="mb-2">
                                    {post.image2 && !image2Deleted && (
                                        <div className="d-flex align-items-center">
                                            <img src={`${post.image2}`} alt="Image 2" style={{ maxWidth: '200px', marginRight: '10px' }} />
                                            <button className="btn btn-danger" onClick={handleImage2Delete}>Delete Image 2</button>
                                        </div>
                                    )}
                                    <input type="file" className="form-control mt-2" onChange={(e) => setImage2(e.target.files[0])} />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-danger mt-2 me-2" style={{ width: '100px' }} onClick={handleCancel}>Cancel</button>
                                    <button className="btn btn-success mt-2" style={{ width: '100px' }} onClick={handleUpdate}>Update</button>
                                </div>
                            </>
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default PostDetail;
