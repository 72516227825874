import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { URL_BOARD } from './Url';
import Notice from '../modules/notice';
import ResearchGate from '../modules/researchgate';
import RelatedSites from '../modules/relatedsites';
import Visit from '../modules/visit';
import Google from '../modules/google';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './BoardList.css';
import { displayErrorModal, handleErrorResponse } from '../modules/errorHandler';

const getCsrfToken = () => {
    return document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');
};

const HealthList = ({ content, userInfo, isLoggedIn }) => {
    const [boards, setBoards] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const pageSize = 10;
    const [isWriting, setIsWriting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingPost, setEditingPost] = useState(null);
    const [viewingPost, setViewingPost] = useState(null);
    const titleRef = useRef(null);
    const [quillContent, setQuillContent] = useState('');
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image1Deleted, setImage1Deleted] = useState(false);
    const [image2Deleted, setImage2Deleted] = useState(false);
    const navigate = useNavigate();

    const handleImageChange1 = (event) => {
        setImage1(event.target.files[0]);
        setImage1Deleted(false);
    };

    const handleImageChange2 = (event) => {
        setImage2(event.target.files[0]);
        setImage2Deleted(false);
    };

    const fetchBoards = useCallback(async () => {
        try {
            const csrfToken = getCsrfToken();
            const response = await axios.get(`${URL_BOARD}/health/post?page=${pageNumber}&limit=${pageSize}`, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });
            if (handleErrorResponse(response.data)) {
                setBoards(response.data.posts);
                setTotalPages(Math.ceil(response.data.total_records / pageSize));
                setTotalRecords(response.data.total_records);
            }
        } catch (error) {
            displayErrorModal('An error occurred while fetching the health posts.');
            console.error('Error fetching board posts:', error);
        }
    }, [pageNumber, pageSize]);

    useEffect(() => {
        fetchBoards();
    }, [fetchBoards]);

    const handleSubmit = async () => {
        if (titleRef.current && quillContent) {
            const title = titleRef.current.value;
            const content = quillContent;

            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            if (image1) formData.append('image1', image1);
            if (image2) formData.append('image2', image2);
            if (image1Deleted) formData.append('deleteImage1', 'true');
            if (image2Deleted) formData.append('deleteImage2', 'true');

            const csrfToken = getCsrfToken();

            try {
                let updatedPost;
                if (isEditing && editingPost) {
                    await axios.put(`${URL_BOARD}/health/${editingPost.id}`, formData, {
                        headers: {
                            'X-CSRF-Token': csrfToken,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    const response = await axios.get(`${URL_BOARD}/health/${editingPost.id}`, {
                        headers: {
                            'X-CSRF-Token': csrfToken
                        }
                    });
                    updatedPost = response.data.post;
                } else {
                    const response = await axios.post(`${URL_BOARD}/health/post`, formData, {
                        headers: {
                            'X-CSRF-Token': csrfToken,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    updatedPost = response.data.post;
                    navigate(`/health`); // 게시글 작성 후 /health로 이동
                }

                setIsWriting(false);
                setIsEditing(false);
                setEditingPost(null);
                setViewingPost(updatedPost);
                await fetchBoards();
                setPageNumber(1);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    displayErrorModal(error.response.data.message);
                } else {
                    displayErrorModal('An error occurred while submitting the post.');
                }
                console.error('Error submitting post:', error);
            }
        }
    };

    const handleCancel = () => {
        setIsWriting(false);
        setIsEditing(false);
        setEditingPost(null);
        setViewingPost(null);
        setImage1Deleted(false);
        setImage2Deleted(false);
    };

    const startEditing = (post) => {
        setIsEditing(true);
        setIsWriting(true);
        setEditingPost(post);
        setQuillContent(post.content);
        if (titleRef.current) {
            titleRef.current.value = post.title;
        }
        setImage1Deleted(false);
        setImage2Deleted(false);
    };

    const startWriting = () => {
        setIsWriting(true);
        setIsEditing(false);
        setQuillContent('');
        if (titleRef.current) {
            titleRef.current.value = '';
        }
        setImage1Deleted(false);
        setImage2Deleted(false);
    };

    const startViewing = async (post) => {
        try {
            const csrfToken = getCsrfToken();
            const response = await axios.get(`${URL_BOARD}/health/${post.id}`, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });
            if (response.data && response.data.post) {
                setViewingPost(response.data.post);
                navigate(`/health/${post.id}`);
            } else {
                console.log("No post data found in response.");
            }
        } catch (error) {
            console.error("Error fetching post content:", error);
        }
    };

    const handleImage1Delete = () => {
        setImage1Deleted(true);
        setImage1(null);
    };

    const handleImage2Delete = () => {
        setImage2Deleted(true);
        setImage2(null);
    };

    const getPostNumber = (index) => {
        return totalRecords - (pageNumber - 1) * pageSize - index;
    };

    const startPage = Math.max(1, pageNumber - Math.floor(pageSize / 2));
    const endPage = Math.min(startPage + pageSize - 1, totalPages);

    return (
        <div className="container">
            <div className="row">
                {!(isWriting || isEditing || viewingPost) && (
                    <div className="col-lg-8">
                        <div className="list-group card mb-4">
                            {boards.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Author</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Read</th>
                                            <th scope="col">Recommend</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {boards.map((item, index) => (
                                            <tr key={index} onClick={() => startViewing(item)} style={{ cursor: 'pointer' }}>
                                                <th scope="row">{getPostNumber(index)}</th>
                                                <td>
                                                    {item.title} {item.comment_count > 0 && <span style={{ color: '#999' }}>+{item.comment_count}</span>}
                                                </td>
                                                <td>{item.user_name}</td>
                                                <td>{new Date(item.created_at).toLocaleDateString()}</td>
                                                <td>{item.hit}</td>
                                                <td>{item.recommend}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center">There is no post</p>
                            )}
                            <br /><br />
                            {boards.length > 0 && (
                                <div>
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1}>
                                                <span aria-hidden="true">&lt;</span>
                                            </button>
                                        </li>
                                        {[...Array(endPage - startPage + 1)].map((_, i) => {
                                            const page = startPage + i;
                                            return (
                                                <li key={page} className={`page-item ${page === pageNumber ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => setPageNumber(page)}>{page}</button>
                                                </li>
                                            );
                                        })}
                                        <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === totalPages}>
                                                <span aria-hidden="true">&gt;</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {isLoggedIn && (
                                <button className="btn btn-sm btn-primary mt-3" style={{ float: 'right', width: '80px' }} onClick={startWriting}>Write</button>
                            )}
                        </div>
                        <br /><br />
                    </div>
                )}
                {(isWriting || isEditing) && (
                    <div className="col-lg-12 mt-3 card mb-4">
                        <input type="text" ref={titleRef} className="form-control mb-2" placeholder="Title" defaultValue={isEditing ? editingPost.title : ''} />
                        <ReactQuill
                            value={quillContent}
                            onChange={setQuillContent}
                            style={{ height: '300px', marginBottom: '40px' }}
                        />
                        <input type="file" className="form-control mb-2" onChange={handleImageChange1} />
                        {isEditing && editingPost.image1 && !image1Deleted && (
                            <div className="d-flex align-items-center mb-2">
                                <img src={`${editingPost.image1}`} alt="Image 1" style={{ maxWidth: '200px', marginRight: '10px' }} />
                                <button className="btn btn-danger" onClick={handleImage1Delete}>Delete Image 1</button>
                            </div>
                        )}
                        <input type="file" className="form-control mb-2" onChange={handleImageChange2} />
                        {isEditing && editingPost.image2 && !image2Deleted && (
                            <div className="d-flex align-items-center mb-2">
                                <img src={`${editingPost.image2}`} alt="Image 2" style={{ maxWidth: '200px', marginRight: '10px' }} />
                                <button className="btn btn-danger" onClick={handleImage2Delete}>Delete Image 2</button>
                            </div>
                        )}
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-danger mt-2 me-2" style={{ width: '100px' }} onClick={handleCancel}>Cancel</button>
                            <button className="btn btn-success mt-2" style={{ width: '100px' }} onClick={handleSubmit}>{isEditing ? 'Update' : 'Submit'}</button>
                        </div>
                    </div>
                )}
                {viewingPost && !isEditing && (
                    <div className="col-lg-12 mt-3 card mb-4">
                        <h2>{viewingPost.title}</h2>
                        <p>By {viewingPost.user_name} on {new Date(viewingPost.created_at).toLocaleDateString()}</p>

                        {/* 이미지 출력 섹션 */}
                        {viewingPost.image1 && (
                            <img src={`${viewingPost.image1}`} alt="Image 1" style={{ maxWidth: '100%', marginBottom: '10px' }} />
                        )}
                        {viewingPost.image2 && (
                            <img src={`${viewingPost.image2}`} alt="Image 2" style={{ maxWidth: '100%', marginBottom: '10px' }} />
                        )}

                        <div className="post-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(viewingPost.content) }} />
                        <div className="d-flex justify-content-between mt-2">
                            <button className="btn btn-secondary" style={{ width: '200px' }} onClick={() => {
                                setViewingPost(null);
                                fetchBoards();
                            }}>Back to List</button>
                            <div>
                                {isLoggedIn && userInfo.id === viewingPost.user_id && (
                                    <button className="btn btn-primary" style={{ width: '100px' }} onClick={() => startEditing(viewingPost)}>Edit</button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {!(isWriting || isEditing || viewingPost) && (
                    <div className="col-lg-4">
                        {/*Notice widget*/}
                        <div className="card mb-3" id="widget-notice"><Notice /></div>
                        {/*ResearchGate widget*/}
                        <div className="card mb-3" id="widget-researchgate"><ResearchGate /></div>
                        {/*Related sites widget*/}
                        <div className="card mb-3" id="widget-relatedsites"><RelatedSites /></div>
                        {/*Counter widget*/}
                        <div className="card mb-3" id="widget-counter"><Visit /></div>
                        {/*Google search widget*/}
                        <div className="card mb-3" id="widget-google"><Google content={content} /></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HealthList;
